import {createSlice} from '@reduxjs/toolkit';

export const paymentSystemSlice = createSlice({
    name: 'PaymentSystem',
    initialState: {
        data: [],
    },
    reducers: {
        fetchPaymentSystem(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchPaymentSystem} = paymentSystemSlice.actions;