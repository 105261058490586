import {createSlice} from '@reduxjs/toolkit';

export const attributeValuesSlice = createSlice({
    name: 'attributeValues',
    initialState: {
        data: [],
    },
    reducers: {
        fetchAttributeValues(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchAttributeValues} = attributeValuesSlice.actions;