import {createSlice} from '@reduxjs/toolkit';

export const storeUsersPermissionsSlice = createSlice({
    name: 'storeUsersPermissions',
    initialState: {
        data: [],
    },
    reducers: {
        fetchStoreUsersPermissions(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchStoreUsersPermissions} = storeUsersPermissionsSlice.actions;