import {createSlice} from '@reduxjs/toolkit';

export const usersPermissionsSlice = createSlice({
    name: 'userPermissions',
    initialState: {
        data: [],
    },
    reducers: {
        fetchUsersPermissions(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchUsersPermissions} = usersPermissionsSlice.actions;