import {createSlice} from '@reduxjs/toolkit';

export const stockSlice = createSlice({
    name: 'stock',
    initialState: {
        data: [],
    },
    reducers: {
      addShipment: (state, action) => {
        state.data.push(action.payload);
      },
      setShipments: (state, action) => {
        state.data = action.payload;
      },
    },
});
export const { addShipment, setShipments } = stockSlice.actions;