import { createSlice } from '@reduxjs/toolkit';

export const rolePermissionsSlice = createSlice({
    name: 'RolePermissions',
    initialState: {
        data: [],
    },
    reducers: {
        fetchRolePermissions(state, action) {
            state.data = action.payload;
        }
    },
});
export const { fetchRolePermissions } = rolePermissionsSlice.actions;