import {createSlice} from '@reduxjs/toolkit';

export const countrySlice = createSlice({
    name: 'Countries',
    initialState: {
        data: [],
    },
    reducers: {
        fetchCountry(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchCountry} = countrySlice.actions;