import {createSlice} from '@reduxjs/toolkit';

export const storeUserRolesSlice = createSlice({
    name: 'storeUserRoles',
    initialState: {
        data: [],
    },
    reducers: {
        fetchStoreUserRoles(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchStoreUserRoles} = storeUserRolesSlice.actions;