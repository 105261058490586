import {createSlice} from '@reduxjs/toolkit';

export const productCategoriesSlice = createSlice({
    name: 'productCategories',
    initialState: {
        data: [],
    },
    reducers: {
        fetchProductCategories(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchProductCategories} = productCategoriesSlice.actions;