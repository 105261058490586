import {createSlice} from '@reduxjs/toolkit';

export const storeSlice = createSlice({
    name: 'Stores',
    initialState: {
        data: [],
    },
    reducers: {
        fetchStore(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchStore} = storeSlice.actions;