import {createSlice} from '@reduxjs/toolkit';

export const usersSlice = createSlice({
    name: 'Users',
    initialState: {
        data: [],
    },
    reducers: {
        fetchUsers(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchUsers} = usersSlice.actions;