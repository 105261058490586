import {createSlice} from '@reduxjs/toolkit';

export const productsSlice = createSlice({
    name: 'Products',
    initialState: {
        data: [],
    },
    reducers: {
        fetchProducts(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchProducts} = productsSlice.actions;