import axios from 'axios';
import { API_URL } from 'src/configs/app-global';

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});

const getAuthToken = () => localStorage.getItem('_authToken');
const getRefreshToken = () => localStorage.getItem('_refreshToken');
const clearTokens = () => {
  localStorage.removeItem('_authToken');
  localStorage.removeItem('_refreshToken');
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.code === 'ECONNABORTED') {
      clearTokens();
      return Promise.reject(new Error('İstek zaman aşımına uğradı'));
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          clearTokens();
          window.location.href = '/login';
          return Promise.reject(new Error('Yenileme tokeni bulunamadı'));
        }

        const response = await axios.post(
          `${API_URL}/admin/refresh-token`,
          { data: { refreshToken } },
          { timeout: 10000 }
        );

        const { accessToken, refreshToken: newRefreshToken } = response.data.data;
        localStorage.setItem('_authToken', accessToken);
        localStorage.setItem('_refreshToken', newRefreshToken);

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        if(refreshError.status === 422){
          clearTokens();
          window.location.href = '/login';
          return Promise.reject(new Error('Yenileme tokeni isteği zaman aşımına uğradı'));
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
