import {configureStore} from '@reduxjs/toolkit'

import {citySlice} from "./features/city";
import {usersSlice} from "./features/users";
import {storeSlice} from "./features/store";
import {countrySlice} from "./features/country";
import {productsSlice} from "./features/products";
import {districtSlice} from "./features/district";
import {variantsSlice} from "./features/variants";
import {storeUserSlice} from "./features/storeUser";
import {attributesSlice} from "./features/attribute"
import {categoriesSlice} from "./features/categories";
import {appSettingsSlice} from "./features/appSettings";
import {storeUserRolesSlice} from "./features/storeUserRoles";
import {attributeValuesSlice} from "./features/attributeValues";
import {productCategoriesSlice} from "./features/productCategories";
import {categoryAttributesSlice} from "./features/categoryAttributes";
import {storeUsersPermissionsSlice} from "./features/storeUsersPermissions";
import {rolePermissionsSlice} from "./features/rolePermissions";
import {usersPermissionsSlice} from "./features/userPermissions";
import {paymentSystemSlice} from "./features/paymentSystemOptionSettings"
import {meSlice} from "./features/me";
import {stockSlice} from './features/stock';



export const store = configureStore({
    reducer: {
        store: storeSlice.reducer,
        country: countrySlice.reducer,
        city:citySlice.reducer,
        district:districtSlice.reducer,
        appSettings:appSettingsSlice.reducer,
        storeUserRoles:storeUserRolesSlice.reducer,
        storeUsersPermissions:storeUsersPermissionsSlice.reducer,
        attributes:attributesSlice.reducer,
        attributeValues:attributeValuesSlice.reducer,
        categories:categoriesSlice.reducer,
        categoryAttributes:categoryAttributesSlice.reducer,
        storeUser:storeUserSlice.reducer,
        products:productsSlice.reducer,
        users: usersSlice.reducer,
        variants: variantsSlice.reducer,
        productCategories: productCategoriesSlice.reducer,
        rolePermissions: rolePermissionsSlice.reducer,
        userPermissions: usersPermissionsSlice.reducer,
        paymentSystem: paymentSystemSlice.reducer,
        me:meSlice.reducer,
        stock: stockSlice.reducer
    },
})