import {createSlice} from '@reduxjs/toolkit';

export const meSlice = createSlice({
    name: 'me',
    initialState: {
        data: [],
    },
    reducers: {
        fetchMe(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchMe} = meSlice.actions;