import {createSlice} from '@reduxjs/toolkit';

export const variantsSlice = createSlice({
    name: 'Variants',
    initialState: {
        data: [],
    },
    reducers: {
        fetchVariants(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchVariants} = variantsSlice.actions;