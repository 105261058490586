import { createSlice } from '@reduxjs/toolkit';

export const storeUserSlice = createSlice({
  name: 'storeUser',
  initialState: {
    data: [],
  },
  reducers: {
    fetchStoreUser(state, action) {
      state.data = action.payload;
    },
  },
});
export const { fetchStoreUser } = storeUserSlice.actions;
