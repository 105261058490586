import {createSlice} from '@reduxjs/toolkit';

export const categoryAttributesSlice = createSlice({
    name: 'categoryAttributes',
    initialState: {
        data: [],
    },
    reducers: {
        fetchCategoryAttributes(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchCategoryAttributes} = categoryAttributesSlice.actions;