import SvgColor from 'src/components/svg-color';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const childrenIcon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 10 }} />
);

const NavConfigComponent = () => {
  const { t } = useTranslation();

  const navConfig = [
    {
      title: t('Raporlar'),
      path: '/',
      icon: icon('ic_analytics'),
      children: [
        {
          icon: childrenIcon('ic_analytics'),
          title: "Güncelleme Notları",
          path: '/update-notes',
        }
      ],
    },
    // {
    //   title: t('Işletmeler'),
    //   path: '/businesses',
    //   icon: icon('ic_users'),
    // },
    {
      // title: t('Bildirim İşlemleri Detay'),
      title: 'Bildirim İşlemleri (yakında)',
      // path: '/paymentProcedures',
      icon: icon('ic_detail'),
    },
    {
      title: t('Komisyon Yönetimi'),
      path: '/commissionManagement',
      icon: icon('comission'),
      children: [
        {
          icon: childrenIcon('comission'),
          title: "Komisyon Oranları",
          path: '/commissionRate',
        },
      ],
    },
    {
      title: t('Ödeme Sistemleri'),
      path: '/payment-system-option-settings',
      icon: icon('ic_credit_card'),
      children: [
        {
          icon: childrenIcon('ic_credit_card'),
          title: "Ödeme Sistemi Ekle",
          path: '/payment-system-option-settings/create',
        },
      ],
    },
    {
      title: 'Siparişler',
      path: '/orders',
      icon: icon('ic_orders'),
      children: [
        {
          icon: childrenIcon('ic_orders'),
          title: "İptal Edilen Siparişler",
          path: '/orders/canceled',
        }
      ],
    },
    {
      title: t("Depolar"),
      path: "/stores",
      icon: icon("ic_warehouse"),
      children: [
        {
          icon: childrenIcon("ic_warehouse"),
          title: "Depo Oluştur",
          path: '/stores/new-store',
        },
        {
          icon: childrenIcon("store_types"),
          title: t("Depo Tipleri"),
          path: "/store-types",
        },
        {
          icon: childrenIcon('storage'),
          title: t('Depo Kullanıcıları'),
          path: "/store-users",
        },
        {
          icon: childrenIcon("store-user-permissions"),
          title: t("Depo Kullancıları Rolleri"),
          path: "/store-user-roles",
        },
        {
          icon: childrenIcon("ic_user"),
          title: t("Depo Kullanıcıları İzinleri"),
          path: "/store-user-permissions",
        }

      ],
    },
    {
      title: t('Kategoriler'),
      path: '/categories',
      icon: icon('list'),
    },
    {
      title: t('Ürünler'),
      path: '/products',
      icon: icon('ic_product_attributes'),
      children: [
        {
          icon: childrenIcon('ic_product_attributes'),
          title: "Ürün Ekle",
          path: '/products/create',
        },
        {
          icon: childrenIcon('product-attributes'),
          title: t('Ürün Özellikleri'),
          path: '/attributes',
        },
      ],
    },
    {
      title: 'Stok Yönetimi',
      path: '/stock-management',
      icon: icon('ic_stock'),
      children: [
        {
          icon: childrenIcon('ic_stock'),
          title: "Yeni Sevkiyat Başlat",
          path: '/stock-management/create',
        }
      ],
    },
    {
      title: t('Canlı Yayınlar'),
      path: '/streams',
      icon: icon('ic_video'),
    },
    {
      title: t('Ülkeler'),
      path: '/country',
      icon: icon('ic_countries'),
      children: [
        {
          icon: childrenIcon('ic_countries'),
          title: "Ülke Ekle",
          path: '/country/create',
        },
      ],
    },
    {
      title: 'İletişim',
      path: '/contact',
      icon: icon('ic_contact'),
    },
    {
      title: 'İş Başvuruları',
      path: '/job-applications',
      icon: icon('ic_job-applications'),
    },
    {
      title: 'Kuryeler',
      path: '/courier',
      icon: icon('ic_courier'),
      children: [
        {
          icon: childrenIcon('ic_courier'),
          title: "Kurye Başvuruları",
          path: '/courier-applications',
        },
      ],
    },
    // {
    //   title: t('Kullanıcılar'),
    //   path: '/users',
    //   icon: icon('ic_users'),
    // },
    {
      title: t('Uygulama Ayarları'),
      path: '/app-settings',
      icon: icon('ic_app_setting'),
    },
  ];

  return navConfig;
};

export default NavConfigComponent;
