import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import storeUserServices from '../services/storeUserServices';
import adminServices from '../services/adminServices';
import { useDispatch } from 'react-redux';
import { fetchMe } from '../redux/features/me';
import { toast } from 'react-toastify';

// eslint-disable-next-line react/prop-types
const AuthMiddleware = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('_authToken');
        if (!token) {
          setIsAuthenticated(false);
          return;
        }
        adminServices
          .me(token)
          .then((res) => {
            setIsAuthenticated(res);
            dispatch(fetchMe(res.data.data));
          })
          .catch((err) => {
            toast.error(err.response.data.errors);
            setIsAuthenticated(false);
          });
      } catch (error) {
        console.error('Auth check failed', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/login', { replace: true });
      localStorage.removeItem('_authToken');
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? children : null;
};

export default AuthMiddleware;
