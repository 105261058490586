import {createSlice} from '@reduxjs/toolkit';

export const citySlice = createSlice({
    name: 'City',
    initialState: {
        data: [],
    },
    reducers: {
        fetchCity(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchCity} = citySlice.actions;