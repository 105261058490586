import {createSlice} from '@reduxjs/toolkit';

export const attributesSlice = createSlice({
    name: 'attributes',
    initialState: {
        data: [],
    },
    reducers: {
        fetchAttributes(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchAttributes} = attributesSlice.actions;